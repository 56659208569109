import { useTranslation } from '@lyearn/i18n';
import LoginPage from '@/pages/auth/Login';

const Login = () => {
  useTranslation(['Auth']);
  return <LoginPage />;
};

export default Login;
const isPublicPage = () => true;
Login.isPublicPage = isPublicPage;
